import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const ProjectDetailsImage = ({ filename, alt }) => {
  const { images } = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(quality: 75, maxWidth: 900) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const image = images.edges.find((n) => n.node.relativePath.includes(filename));
  const imageFluid = image ? image.node.childImageSharp.fluid : null;

  return imageFluid ? <Img className="project-details__image" alt={alt} fluid={imageFluid} /> : null;
};

ProjectDetailsImage.propTypes = {
  filename: PropTypes.string,
  alt: PropTypes.string,
};

export default ProjectDetailsImage;
