import React from 'react';
import { animateScroll } from 'react-scroll';
import { ChevronUp } from 'react-feather';

function ScrollToTop() {
  return (
    <div className="scroll-to-top">
      <button
        type="button"
        aria-label="Scroll to top"
        onClick={() => animateScroll.scrollToTop({ smooth: 'easeInOutQuad', duration: 500 })}
      >
        <ChevronUp color="#000" />
      </button>
    </div>
  );
}

export default ScrollToTop;
