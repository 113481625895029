import React from 'react';
import SEO from '../components/SEO/SEO';
import Layout from '../components/Layout/Layout';
import PlayChess from '../components/PlayChess/PlayChess';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';

const headData = {
  title: 'Play Chess project',
  description: 'A simple chess board that let you play with your friend',
  lang: 'en',
};

const index = () => (
  <>
    <SEO title={headData.title} description={headData.description} lang={headData.lang} />
    <Layout>
      <PlayChess />
      <ScrollToTop />
    </Layout>
  </>
);

export default index;
