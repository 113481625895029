import React from 'react';
import { ExternalLink } from 'react-feather';
import PlayChessBackgroundImage from '../Image/PlayChessBackgroundImage';
import ProjectDetailsImage from '../Image/ProjectDetailsImage';

const project = {
  title: 'Play Chess',
  shortDesc: 'A simple chess board to play with your friend.',
  shortDesc2: 'Technologies used: WebSocket, NodeJS, ReactJS, Chessground, Chess.js.',
  link: 'https://chess.fpooon.com',
  screenshots: [
    {
      image: 'play_chess_ss_1.png',
      title: 'Create new match',
    },
    {
      image: 'play_chess_ss_2.png',
      title: 'Join match',
    },
    {
      image: 'play_chess_ss_3.png',
      title: 'Match is over. Checkmate!',
    },
    {
      image: 'play_chess_ss_4.png',
      title: 'Match is over. Stalemate!',
    },
  ],
};

function PlayChess() {
  return (
    <>
      <PlayChessBackgroundImage>
        <section className="play-chess">
          <header className="header">
            <h1 className="header__title">{project.title}</h1>
            <p className="header__subtitle">{project.shortDesc}</p>
            <p className="header__subtitle">{project.shortDesc2}</p>
            <p className="header__links">
              <a href={project.link} target="_blank" rel="noreferrer" aria-label="Open Play Chess">
                Link to App
                <ExternalLink color="#fff" style={{ marginLeft: '0.25em' }} />
              </a>
            </p>
          </header>
        </section>
      </PlayChessBackgroundImage>
      <article className="play-chess__overview">
        <h2>The Story</h2>
        <p>
          I encountered WebSocket for the first time during my day job at the time. I was looking for a way to learn
          more about the technology behind “full-duplex, bidirectional communication with the server over a single
          long-lasting connection”.
        </p>
        <p>
          The bidirectional communication characteristic of WebSocket is pretty cool. I thought about a chess game where
          two players could play online with each other, and I could leverage this technology for a low latency and
          reliable gameplay. I initially used Angular to build this project. I&apos;ve updated and replaced Angular with
          React and React Hooks (to learn React Hooks). Backend side used Node and{' '}
          <a href="https://www.npmjs.com/package/ws" aria-label="Go to npm ws library" target="_blank" rel="noreferrer">
            WebSocket
          </a>{' '}
          to open and hold connections. It was simple but fun experience building the project.
        </p>
        <p>I added moves sound to make it more stimulating. You can disable it if it annoys you.</p>
        <p>
          After a match has ended, the “match lobby” will be open for access, so you can go back again and see the match
          result and position.
        </p>
        <p>
          I host backend server on Heroku. If the server has been inactive for a while, and you happen to be the first
          person to interact with the app during that time, it might need few seconds to spin up. So please be patient
          until the connection has been established, or refresh the app.
        </p>
        <section>
          <h2>Screenshots</h2>
          {project.screenshots.map((ss) => (
            <ProjectDetailsImage filename={ss.image} alt={ss.title} />
          ))}
        </section>
      </article>
    </>
  );
}

export default PlayChess;
