import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';

const PlayChessBackgroundImage = ({ alt, children }) => {
  const { phone, tablet, desktop, wide } = useStaticQuery(graphql`
    query {
      phone: file(relativePath: { eq: "play_chess_bg_tablet.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 480) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      tablet: file(relativePath: { eq: "play_chess_bg_tablet.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 768) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      desktop: file(relativePath: { eq: "play_chess_bg_wide.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      wide: file(relativePath: { eq: "play_chess_bg_wide.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 4277) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const backgroundImageFluidStack = [
    phone.childImageSharp.fluid,
    {
      ...tablet.childImageSharp.fluid,
      media: `(min-width: 769px)`,
    },
    {
      ...desktop.childImageSharp.fluid,
      media: `(min-width: 1201px)`,
    },
    {
      ...wide.childImageSharp.fluid,
      media: `(min-width: 1536px)`,
    },
  ];

  return (
    <BackgroundImage
      alt={alt}
      aria-label="Projects Banner"
      fluid={backgroundImageFluidStack}
      role="img"
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        backgroundBlendMode: 'darken',
        backgroundSize: 'cover',
      }}
      title={alt}
    >
      {children}
    </BackgroundImage>
  );
};

PlayChessBackgroundImage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  alt: PropTypes.string,
};

export default PlayChessBackgroundImage;
